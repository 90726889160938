<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item style="margin-bottom: 20px" prop="name" label="名称" :rules="rules">
        <el-input style="width: 300px" id="tipinput" size="small" v-model="formData.name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item prop="name" label="" :rules="rules">
        <div id="draw-station-container"></div>
      </el-form-item>
      <el-form-item prop="sort" label="排序">
        <el-input style="width: 300px" size="small" v-model="formData.sort" placeholder="请输入" clearable></el-input>
      </el-form-item>

      <el-form-item label=" ">
        <el-button size="small" type="" @click="$router.back()">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import { key, code } from '@/enum/dict.js'
import AMapLoader from '@amap/amap-jsapi-loader'

window._AMapSecurityConfig = {
  securityJsCode: code // '「申请的安全密钥」',
}
export default {
  name: 'AddOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        name: '',
        sort: 0,
        longitude: '',
        latitude: ''
      },
      // 地图对象
      AMap: null,
      // 地图实例对象
      map: null,
      // 经纬度
      location: {
        lat: '',
        lng: ''
      },
      // 搜索输入
      auto: null,
      placeSearch: null
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.getAdminDetail()
    } else {
      this.initMap()
    }
  },

  methods: {
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            await addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getAdminDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
      this.initMap()
    },
    initMap() {
      AMapLoader.load({
        key: key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap
          this.map = new AMap.Map('draw-station-container', {
            resizeEnable: true,
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 16, //初始化地图级别
            center: [this.formData.latitude || '117.227267', this.formData.latitude || '31.820567'] //初始化地图中心点位置
            // mapStyle: 'amap://styles/macaron', //设置地图的显示样式
          })

          // 地图控件
          this.map.addControl(new AMap.Scale())
          // 搜索框自动完成类
          this.auto = new AMap.AutoComplete({
            input: 'tipinput'
          })
          //构造地点查询类
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map
          })

          // 当选中某条搜索记录时触发
          this.auto.on('select', this.select)
          // poi覆盖物点击事件
          this.placeSearch.on('markerClick', this.clickMarkerHandler)
          // 地图点击事件
          this.map.on('click', this.clickMapHandler)
          //1.点击地图任意位置生成一个marker
          let iconEnd = new AMap.Icon({
            size: new AMap.Size(25, 34),
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
            imageSize: new AMap.Size(25, 34),
            imageOffset: new AMap.Pixel(0, 0)
          })
          this.marker = new AMap.Marker({
            position: new AMap.LngLat(this.formData.longitude || '117.227267', this.formData.latitude || '31.820567'),
            icon: iconEnd
          })
          this.map.add(this.marker)
          this.map.setFitView()
          this.map.setCenter([this.formData.longitude || '117.227267', this.formData.latitude || '31.820567'])
        })
        .catch((e) => {
          console.log(e)
        })
    },

    // 当选中某条记录时会触发
    select(e) {
      console.log(e)
      const { lat, lng } = e.poi.location
      // this.$emit('update:lat', lat)
      // this.$emit('update:lng', lng)
      this.formData.latitude = lat
      this.formData.longitude = lng
      this.formData.name = e.poi.name
      // this.getLngLatService(lat, lng)
      this.placeSearch.setCity(e.poi.adcode)
      this.placeSearch.search(e.poi.name, (status, res) => {
        const { lat, lng } = res.poiList.pois[0].location

        this.getLngLatService(lat, lng)
        console.log(lat, lng)
        this.formData.latitude = lat
        this.formData.longitude = lng
        this.map.setCenter([lng, lat])
      }) //关键字查询查询
    },

    // 点击地图事件
    clickMapHandler(e) {
      // this.location.lng = e.lnglat.getLng()
      // this.location.lat = e.lnglat.getLat()
      this.formData.latitude = e.lnglat.getLat()
      this.formData.longitude = e.lnglat.getLng()
      this.map.setCenter([e.lnglat.getLng(), e.lnglat.getLat()])
      this.getLngLatService(e.lnglat.getLat(), e.lnglat.getLng())
      this.$message.success('选择成功')
    },
    // 点击poi覆盖物事件
    clickMarkerHandler(e) {
      console.log(111)
      // this.location.lng = e.data.location.lng
      // this.location.lat = e.data.location.lat
      this.formData.latitude = e.data.location.lat
      this.formData.longitude = e.data.location.lng
      this.map.setCenter([e.data.location.lng, e.data.location.lat])
    },
    getLngLatService(lat, lng) {
      console.log(lat, lng)
      let pos = [lng, lat]
      let lnglat = new AMap.LngLat(lng, lat)
      let geocoder = new AMap.Geocoder({
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        city: '全国'
      })
      if (this.marker) this.map.remove(this.marker)
      //1.点击地图任意位置生成一个marker
      let iconEnd = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        imageSize: new AMap.Size(25, 34),
        imageOffset: new AMap.Pixel(0, 0)
      })
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(lng, lat),
        icon: iconEnd
      })
      this.map.add(this.marker)

      let address = ''
      //2.将位置转化为坐标点-->地理信息
      //3.根据地理信息（地址）进行搜索获取详细信息！
      // geocoder.getAddress(lnglat, (status, result) => {
      //   if (status === 'complete' && result.regeocode) {
      //     address = result.regeocode.formattedAddress
      //     console.log(result.regeocode.addressComponent, '@@')
      //     let res = {
      //       pos: pos,
      //       address: address
      //     }
      //     const { province, city, district } = result.regeocode.addressComponent
      //     // this.$emit('update:province', province)
      //     // this.$emit('update:city', city)
      //     // this.$emit('update:district', district)
      //     //需求：固定的窗体信息进行展示！
      //     // bus.$emit('shareAddressDetails', res)
      //   } else {
      //     this.$message.error('根据经纬度查询地址失败')
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  #draw-station-container {
    padding: 0px;
    margin: 0px;
    width: 800px;
    height: 300px;
  }
}
</style>
