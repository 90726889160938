import { http } from '@/http/axios.js'
//班级状态
export const CLASS_STATUS = [
  { value: 10, name: '开放' },
  { value: 20, name: '满班后停止' },
  { value: 30, name: '停止招生' }
]

// 老师类型
export const TEACHER_TYPE = [
  { value: 0, name: '老师' },
  { value: 1, name: '助教' }
]
export function dictConfigs(dictKey) {
  return http({
    url: `/company/basic/dictValue/dictEnums`,
    method: 'GET',
    params: { dict_key: dictKey }
  }).then((items) => {
    return items.map((item) => {
      return {
        value: item.value,
        name: item.name
      }
    })
  })
}

export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

/**
 * 老师管理
 */
// 老师列表
export const dict_teacher_status = [
  {
    label: '下架',
    value: 10
  },
  {
    label: '上架',
    value: 20
  }
]
export const code = '5ff55006fe7c9254f90859397a308524'
export const key = '3768813424129a70d14ab4eb3c2f9938'
